import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DrugFreeIcon from '../images/drug-free.png';
import LightweightIcon from '../images/lightweight.png';
import SimpleToUseIcon from '../images/simple-to-use.png';

const items = [
  {
    location: 'left',
    icon: LightweightIcon,
    title: 'Lightweight',
    description:
      "Many smart watches can be clunky and heavy making it hard to sleep. Our product is designed to be lightweight and comfortable: engineered so you won't even know it's there."
  },
  {
    location: 'middle',
    icon: DrugFreeIcon,
    title: 'Drug-Free',
    description:
      "Drug side-effects, interactions, and long-term effects aren't things that should keep you up at night. So don't them."
  },
  {
    location: 'right',
    icon: SimpleToUseIcon,
    title: 'Easy to Use',
    description:
      'Life is hard. Sleeping should be easy. Just put it on and let us do the rest for you.'
  }
];

export default function Pricing() {
  return (
    <Container
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Highlights
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Don't settle when it comes to your health.
        </Typography>
      </Box>
      <Grid
        container
        gap={3}
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        {items.map((item, index) => (
          <Grid
            size={{ xs: 12, sm: 6, md: 4 }}
            key={index}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4
                },
                item.location === 'middle' &&
                ((theme) => ({
                  border: 'none',
                  background:
                    'radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))',
                  boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                  ...theme.applyStyles('dark', {
                    background:
                      'radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))',
                    boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`
                  })
                }))
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 2
                    },
                    item.location === 'middle'
                      ? { color: 'grey.100' }
                      : { color: '' }
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {item.title}
                  </Typography>
                  <img src={item.icon} width={50} />
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    gap: 1.5,
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    component={'span'}
                    sx={[
                      item.location === 'middle'
                        ? { color: 'grey.50' }
                        : { color: null }
                    ]}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
