import * as React from 'react';
import Container from '@mui/material/Container';
import Highlights from './Highlights';

export default function Product() {
  return (
    <Container
      id="product"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white'
      }}
    >
      <Highlights />
    </Container>
  );
}
