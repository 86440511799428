import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PersonLayingStars2 from '../images/person-laying-stars-2.webp';
import { Button, Chip, IconButton } from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

export default function Hero() {
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box
      sx={(theme) => ({
        // muted background image of person laying on stars
        backgroundImage: `url(${PersonLayingStars2})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.mode === 'light' ? 'primary.light' : 'primary.dark',
        color:
          theme.palette.mode === 'light' ? 'primary.dark' : 'primary.light',
        backgroundBlendMode: 'multiply'
      })}
    >
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          // backgroundImage:
          //   theme.palette.mode === 'light'
          //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
          //     : `linear-gradient(#02294F, ${alpha('#090E10', 0.5)})`,
          // backgroundSize: '100% 20%',
          backgroundRepeat: 'no-repeat'
          // background: 'rgba(0, 0, 0, 0.1)',
        })}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 8 }
          }}
        >
          <Stack spacing={6} useFlexGap direction="row" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
            <Stack spacing={2} useFlexGap direction="column">
              <Typography
                textAlign="left"
                color="text.primary"
                component="h2"
                variant="h2"
                sx={{ marginTop: { xs: '0rem', md: '1rem' }, alignSelf: 'start', width: '100%', fontSize: { xs: '2.25rem', sm: '3rem', md: '4rem' }  }}
              >
                Great sleep is
                <br />
                no longer just a dream
              </Typography>
              {/* <Button
                variant="outlined"
                sx={{ width: '50%' }}
                onClick={() => {
                  scrollToSection('product');
                }}
              >
                Learn More
              </Button> */}
            </Stack>
            <Box component="img" src="static/images/app-and-watch.png" sx={{ width: { xs: '100%', md: '50%' }}} />
          </Stack>
          <IconButton
            onClick={() => {
              scrollToSection('product');
            }}
          >
            <ExpandCircleDownOutlinedIcon
              sx={{ fontSize: '4rem', color: 'secondary.main' }}
            />
          </IconButton>
        </Container>
      </Box>
    </Box>
  );
}
