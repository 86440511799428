import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ScaleIcon from '@mui/icons-material/Scale';
import MediationIcon from '@mui/icons-material/Mediation';
import BedIcon from '@mui/icons-material/Bed';
import DrugFreeIcon from '../images/drug-free.png';
import LightweightIcon from '../images/lightweight.png';
import SimpleToUseIcon from '../images/simple-to-use.png';

const items = [
  {
    icon: <BedIcon />,
    title: 'Build your long term sleep health starting today',
    description:
      'Our revolutionary device not only monitors but improves your sleep by delivering targeted deep sleep enhancing stimulation.'
  },
  {
    icon: <ScaleIcon />,
    title: 'Improve your sleep in just few weeks',
    description:
      'Somno Tech is a wearable device that uses smart electrocutaneous stimulation to enhance deep sleep.'
  }
];

export default function Highlights() {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 }
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' }
        }}
      >
        <Typography component="h2" variant="h4" sx={{ marginBotton: '1rem' }}>
          Backed by Science
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: 'grey.400', marginTop: '1rem' }}
        >
          We've used the latest research to create a device backed by science
          and technology to help you get a better night's sleep.
        </Typography>
      </Box>
      <Grid container spacing={2.5}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Stack
              direction="column"
              color="inherit"
              component={Card}
              spacing={1}
              useFlexGap
              sx={{
                p: 3,
                height: '100%',
                border: '1px solid',
                borderColor: 'grey.800'
              }}
            >
              <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
              <div>
                <Typography fontWeight="medium" gutterBottom>
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey.400' }}>
                  {item.description}
                </Typography>
              </div>
            </Stack>
          </Grid>
        ))}
        <Grid item xs={12} sm={12} md={4} key={3}>
          <Stack
            direction="column"
            color="inherit"
            component={Card}
            spacing={1}
            useFlexGap
            sx={{
              p: 3,
              height: '100%',
              border: '1px solid',
              borderColor: 'grey.800'
            }}
          >
            <Box sx={{ opacity: '50%' }}>
              <MediationIcon />
            </Box>
            <div>
              <Typography fontWeight="medium" gutterBottom>
                Great sleep is no longer just a dream
              </Typography>
              <Typography variant="body2" sx={{ color: 'grey.400' }}>
                Somno Tech has been designed for people who...
              </Typography>
              <ul>
                <li>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    Have trouble falling asleep
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    Wake up frequently during the night
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    Have trouble falling back asleep
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    Wake up too early in the morning
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    Feel unrefreshed after a night's sleep
                  </Typography>
                </li>
              </ul>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
